import { createStore } from 'vuex'
import axios from 'axios'
import jwt_decode from "jwt-decode";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export default createStore({
  state: {
    isAuthenticated: false,
    isLoading: false,
    userfirstname: '',
    token: '',
    isPaidUser: false,
  },
  getters: {
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('token')) {
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
        const decoded = jwt_decode(state.token);
        state.userfirstname = capitalizeFirstLetter(decoded.name);
        state.isPaidUser = decoded.contract_type=="paid";
      } else {
        state.token = ''
        state.isAuthenticated = false
        state.userfirstname = '';
        state.isPaidUser = false;
      }
    },
    setIsLoading(state, status) {
      state.isLoading = status
    },
    setToken(state, token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      localStorage.setItem("token", token);
      state.token = token
      state.isAuthenticated = true
      const decoded = jwt_decode(state.token);
      state.userfirstname = capitalizeFirstLetter(decoded.name);
      state.isPaidUser = decoded.contract_type=="paid";
    },
    removeToken(state) {
      state.token = ''
      state.isAuthenticated = false
      localStorage.removeItem('token')
      axios.defaults.headers.common["Authorization"] = "";
      state.userfirstname = '';
      state.isPaidUser = false
    }
  },
  actions: {
  },
  modules: {
  }
})
