<template>
  <div
    id="wrapper"
    class="d-flex flex-column min-vh-100"
    style="
      background-color: #212529;
      color: white;
      font-family: 'Merriweather Sans', sans-serif;
    "
  >
    <header class="site-header sticky-top">
      <SiteHeader />
    </header>

    <div
      class="is-loading-bar has-text-centered"
      v-bind:class="{ 'is-loading': $store.state.isLoading }"
    >
      <div class="lds-dual-ring"></div>
    </div>

    <section class="section">
      <router-view />
    </section>

    <footer class="footer mt-auto py-3">
      <p class="has-text-centered mb-0">Copyright Amphorica inc. &copy; 2023</p>
    </footer>
  </div>
</template>

<script>
import axios from "axios";
import SiteHeader from "@/components/SiteHeader.vue";

export default {
  name: "App",
  components: {
    SiteHeader,
  },
  data: function () {
    return {
      curTime: "",
    };
  },
  beforeCreate() {
    this.$store.commit("setIsLoading", true);
    document.querySelector("body").setAttribute("style", "background:#212529");
    this.$store.commit("initializeStore");
    const token = this.$store.state.token;
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      axios.defaults.headers.common["Authorization"] = "";
    }
  },
  mounted() {
    // this.updateTime();
    this.$store.commit("setIsLoading", false);
  },
  methods: {
    updateTime() {
      // update clock every second
      setInterval(() => {
        let today = new Date();
        this.curTime = today.toLocaleString("en-IL", {
          hour12: false,
          timeZoneName: "shortGeneric",
        });
      }, 1000);
    },
  },
  computed: {},
};
</script>

<style lang="scss">
@import "bootstrap";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#logo {
  top: 0;
  left: 0;
  height: 4em;
}

.profile-pic {
  @extend .rounded-circle;
  height: 2.5em;
  margin-left: 1em;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-color: #ccc transparent #ccc transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.is-loading-bar {
  height: 0;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  &.is-loading {
    height: 80px;
  }
}
</style>
