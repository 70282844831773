<template>
    <div>
        <EditRowPopup :show="this.showPopup" :oldRowData="this.popupRow" @close="this.showPopup=false" @save="saveNewRowData"/>
        
        <div style="height: 30px; margin-bottom: 5px;">
            <div class="mytooltip" style="position: absolute; right: 30px;"> 
                <i class="bi bi-question-circle icon"></i>
                <div class="mytooltiptext">
                    <ul>
                        <li>Edit: Double click on any cell of the row</li>
                        <li>Delete: Select rows with the checkboxes and submit with the appearing button</li>
                        <li>Sort: Click on the column name</li>
                        <li>Filter: Click on the small icon next to the column name which appears when you hover over the column name</li>
                    </ul>
                </div>
            </div>
            <button 
                class="btn btn-danger btn-sm"
                v-show="selectedRows.length > 0" 
                @click="deleteData();"
                style="position: absolute; right: 60px;"
            >
                Delete Rows
            </button>
        </div>
        
        <ag-grid-vue 
            style="height: 83vh; width: 97vw; margin: 0px auto 0px auto;"
            
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="rowData"
            :rowSelection="'multiple'"
            :suppressRowClickSelection="true"
            :onRowDoubleClicked="showEditPopup"
            :animateRows="true"
            
            @grid-ready="onGridReady"
            @selection-changed="onSelectionChanged"

            class="ag-theme-balham-dark"
        >
        </ag-grid-vue>
    </div>
</template>

<script>
import axios from 'axios';
import { AgGridVue } from 'ag-grid-vue3';
import EditRowPopup from "@/components/EditRowPopup.vue";

window.currencyFormatter = function currencyFormatter(params) {
  return '$ ' + params.value;
};
window.weightFormatter = function currencyFormatter(params) {
  return params.value + ' kg';
};

export default {
    components: {
        AgGridVue,
        EditRowPopup,
    },
    data() {
        return {
            rowData: null,
            selectedRows: [],
            gridApi: null,

            defaultColDef: {
                sortable: true,
                filter: true,
                flex: 1,
                resizable: true,
            },
            columnDefs: [
                { field: "", checkboxSelection: true, width: 35, flex: 0},
                { field: "ID",                  headerName: "ID"},
                { field: "MAWB",                headerName: "MAWB" },
                { field: "date",                headerName: "Date" , filter: 'agDateColumnFilter', filterParams: agDateFilter},
                { field: "client",              headerName: "Client"},
                { field: "origin",              headerName: "Origin" },
                { field: "destination",         headerName: "Destination" },
                { field: "weight",              headerName: "Weight", valueFormatter: window.weightFormatter, type: 'rightAligned' },
                { field: "airline_cost",        headerName: "Airline Cost", valueFormatter: window.currencyFormatter, type: 'rightAligned' },
                { field: "broker_cost",         headerName: "Broker Cost",  valueFormatter: window.currencyFormatter, type: 'rightAligned' },
                { field: "cost",                headerName: "Total Cost", valueFormatter: window.currencyFormatter, type: 'rightAligned' },
                { field: "sell_rate",           headerName: "Income",       valueFormatter: window.currencyFormatter, type: 'rightAligned' },
                { field: "sales_person",        headerName: "Sales Person" },
                { field: "shipping_airline" ,   headerName: "Shipping Airline" },
                { field: "type",                headerName: "Type" },
            ],

            // variables for showing editing popup
            showPopup: false,
            oldRowID: '',
            popupRow: null,

            dataHasChanged: false,      // tracks if user changed data. If changes has been made, the PowerBI report refreshes on closing/changing the page
        }
    },

    beforeCreate() {
        this.$store.commit("setIsLoading", true);
        document.title = "Data | Amphorica AirOptimizer™";
    },

    mounted() {
        this.$store.commit("setIsLoading", false);
    },

    async created() {
        this.readData();
    },

    async beforeUnmount() {
        /*
        If changes has been made by the user, the PowerBI report has to be refreshed
        */
        if (this.dataHasChanged) {
            axios
                .get("api/v1/pbi/refreshdataset");
        }
    },

    methods: {
        async readData() {
            axios
                .get("/api/v1/data/get-data")
                .then((response) => {
                    this.rowData = response.data;
                })
        },

        async deleteData(){
            this.dataHasChanged = true;
            const selectedData = this.gridApi.getSelectedRows();
            this.gridApi.applyTransaction({ remove: selectedData });    // don´t know why, but doesn´t work with this.selectedRows
            // console.log(selectedData);
            await axios
                .delete("/api/v1/data/delete-data", {data: selectedData})
                // .then((response) => {
                //     console.log(">> Frontend: ,", response.data);
                // })
        },

        onGridReady(params) {
            this.gridApi = params.api;
        },

        onSelectionChanged() {
            this.selectedRows = this.gridApi.getSelectedRows();
            // console.log(this.selectedRows.length);
        },

        showEditPopup(row) {
            this.popupRow = row.data;
            this.oldRowID = row.data.ID;
            this.showPopup = true;
        },

        async saveNewRowData(newRowData) {
            // console.log(this.popupRow.ID);
            // console.log(newRowData.ID, this.oldRowID, newRowData.ID!=this.oldRowID)
            this.dataHasChanged = true;
            if (newRowData.ID!=this.oldRowID) {
                // console.log("newRowData.ID!=oldRowData.ID");
                // if user has changed the ID, the old row has to be deleted. upload-API will create a new row
                axios
                    .delete("/api/v1/data/delete-data", {data: [{"ID": this.oldRowID }]} )
                    .catch((error) => {
                        console.log(error.response.data["msg"]);
                    })
            }
            axios
                .post("/api/v1/data/upload-data", { rows: [newRowData] } )

            // console.log(newRowData.ID)
            this.gridApi.applyTransaction({ update: [newRowData] });
            this.showPopup = false;
        }
        
    },
        
};

var agDateFilter = {
    comparator: function(filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue;

        if (dateAsString == null) {
            return 0;
        }

        // In the example application, dates are stored as dd/mm/yyyy
        // We create a Date object for comparison against the filter date
        var dateParts = dateAsString.split('-');
        var day = Number(dateParts[2]);
        var month = Number(dateParts[1]) - 1;
        var year = Number(dateParts[0]);
        var cellDate = new Date(year, month, day);

        // Now that both parameters are Date objects, we can compare
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
        return 0;
    },
    browserDatePicker: true,
    minValidYear: 1950,
};
</script>

<style lang="scss" scoped>
@import "@/../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "@/../node_modules/ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
@import "@/../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "@/../node_modules/ag-grid-community/dist/styles/ag-theme-balham-dark.css";

.ag-header-cell-label {
   justify-content: center;
}

.icon {
    font-size: 20px;
}

.mytooltip {
  position: relative;
  display: inline-block;
  visibility: visible;
}
.mytooltip .mytooltiptext {
  visibility: hidden;
  width: 500px;
  background-color: white;
  color: black;
  text-align: left;
  border-radius: 6px;
  border-color: black;
  border-style: groove;
  border-width: thin;
  padding-top: 10px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 105%; 
}
.mytooltip:hover .mytooltiptext {
  visibility: visible;
}
</style>