<template>
  <div class="home">
    <h1 class="mt-5">Welcome To Amphorica's AirOptimizer™</h1>
    <p
      class="content-section"
      v-if="$store.state.isAuthenticated && !$store.state.isPaidUser"
    >
      <strong>Note!</strong><br />This is a free version. Data upload
      functionality is only for demo purposes. The provided report is connected
      to a demo dataset, if you want to upgrade,
      <a style="color: rgba(0, 175, 250)" href="https://amphorica.com/#contact" target="_blank"
        >contact us</a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {};
  },
  mounted() {
    document.title = "Home | Amphorica AirOptimizer™";
    this.$store.commit("setIsLoading", false);
  },
};
</script>

<style lang="scss" scoped>
.content-section {
  background: #202020;
  padding: 10px 20px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  margin-bottom: 20px;
  width: fit-content;
  margin-inline: auto;
}
</style>
