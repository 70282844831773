import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios' 
import bootstrap from 'bootstrap'
import './scss/main.scss';

axios.defaults.baseURL = process.env.NODE_ENV == "development" ? "http://localhost:8000" : "";

const app = createApp(App);
app.use(store);
app.use(router, axios, bootstrap)
app.mount('#app');
