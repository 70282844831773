<template>
  <div class="row justify-content-center">
    <p class="content-section" id="demo-notice" style="display:none;" v-if="$store.state.isAuthenticated && !$store.state.isPaidUser">
      This is a demo report with demo data
    </p>
    <section
      id="report-container"
      class="embed-container col-lg-12 col-md-12 col-sm-12 align-self-center px-5"
    ></section>
    <section class="error-container m-5"></section>
  </div>
</template>

<script>
import axios from "axios";
// import "powerbi-client/dist/powerbi.js";
import * as pbi from "powerbi-client";
import * as $ from "jquery";

export default {
  data: function () {
    return {
      refreshFlag: null,
    };
  },
  beforeCreate() {
    this.$store.commit("setIsLoading", true);
    document.title = "Report | Amphorica AirOptimizer™";
  },
  mounted() {
    this.embedReport();
    // this.$store.commit("setIsLoading", false);
  },
  methods: {
    embedReport: function () {
      const powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );
      var reportContainer = $("#report-container").get(0);

      // Initialize iframe for embedding report
      powerbi.bootstrap(reportContainer, { type: "report" });

      var models = pbi.models;
      var reportLoadConfig = {
        type: "report",
        tokenType: models.TokenType.Embed,

        // Enable this setting to remove gray shoulders from embedded report
        settings: {
          // background: models.BackgroundType.Transparent
          localeSettings: {
            language: "en",
            formatLocale: "en-US",
          },
          panes: {
            pageNavigation: {
              visible: false,
            },
            filters: {
              visible: false,
            },
          },
        },
      };

      axios
        .get("api/v1/pbi/getembedinfo", { dataType: "json" })
        .then((data) => {
          // console.log(data)
          // var embedData = $.parseJSON(data.data);
          let embedData = data.data;
          // console.log(embedData);
          // console.log(typeof embedData);
          // console.log(reportLoadConfig);
          reportLoadConfig.accessToken = embedData.accessToken;

          // You can embed different reports as per your need
          reportLoadConfig.embedUrl = embedData.reportConfig[0].embedUrl;

          // set dataset for embedding
          if (embedData.datasetID) {
            reportLoadConfig.datasetBinding = {
              datasetId: embedData.datasetID,
            };
          }

          // Use the token expiry to regenerate Embed token for seamless end user experience
          // Refer https://aka.ms/RefreshEmbedToken
          // var tokenExpiry = embedData.tokenExpiry;

          // Embed Power BI report when Access token and Embed URL are available
          var report = powerbi.embed(reportContainer, reportLoadConfig);

          // Triggers when a report schema is successfully loaded
          report.on("loaded", () => {
            // console.log("Report load successful");
          });

          // Triggers when a report is successfully embedded in UI
          report.on("rendered", () => {
            // console.log("Report render successful");
            $("#demo-notice").show();
            $(".error-container").hide();
            this.$store.commit("setIsLoading", false);
          });

          report.off("pageChanged");

          report.on("pageChanged", (event) => {
            // console.log(event.detail.newPage);
            // console.log(reportContainer.style);
            // reportContainer.style.height = "calc(100vh - 10em)";
            // reportContainer.style.width = "calc((16/9) * calc(100vh - 10em))";

            // reportContainer.style.width = "100vw";
            if (event.detail.newPage.displayName == "Main") {
              reportContainer.style.height = "calc((9/16) * 100vw)";
            }
            else {
              reportContainer.style.height = "calc((2000/1280) * calc(100vw - 4rem))";
            }
          });


          // Clear any other error handler event
          report.off("error");

          // Below patch of code is for handling errors that occur during embedding
          report.on("error", (event) => {
            // eslint-disable-next-line
            var errorMsg = event.detail;

            // Use errorMsg variable to log error in any destination of choice
            // console.error(errorMsg);
            $("#demo-notice").hide();
            this.$store.commit("setIsLoading", false);
            return;
          });
        })
        .catch((err) => {
          // console.log(err);
          // Show error container
          var errorContainer = $(".error-container");
          $(".embed-container").hide();
          $("#demo-notice").hide();
          errorContainer.show();

          // Format error message
          var errMessageHtml = "<strong> Error Details: </strong> <br/>" + err;
          errMessageHtml = errMessageHtml.split("\n").join("<br/>");

          // Show error message on UI
          errorContainer.html(errMessageHtml);
          this.$store.commit("setIsLoading", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  background-color: #007fff;
  height: 75px;
  width: 100%;
}

header > p {
  color: #ffffff;
  font: bold 1.6em "segoe ui", arial, sans-serif;
  margin-left: 31px;
  padding-top: 20px;
}

main {
  margin: 0 auto;
  width: 100%;
}

section#text-container > div > p {
  font: 1.2em "segoe ui", arial, sans-serif;
}

// section#report-container {
//   // height: calc(0.5625 * 60vw); /* 16:9 aspect ratio */
//   // min-height: calc(0.5625 * 100vw);
//   // height: calc(2.34375 * 100vw);
// }

// @media only screen and (max-width: 575px) {
//     section#report-container {
//         height: calc(0.5625 * 100vw); /* 16:9 aspect ratio */
//     }
// }

footer > p {
  font: 1em "segoe ui", arial, sans-serif;
}

iframe {
  border: none;
}
.content-section {
  background: #202020;
  padding: 10px 20px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  margin-bottom: 20px;
  width: fit-content;
  margin-inline: auto;
}
</style>
