<template>
  <div class="input-group">
    <span class="input-group-text" id="basic-addon1">{{ title }}</span>
    <select class="dark form-select form-select-md" @change="itemClicked($event)">
      <option v-if="options.length > 1" value="" disabled selected hidden>{{ title }}</option>
      <option
        class="dropdown-item"
        :value="option"
        :key="option"
        v-for="option in options"
      >
        {{ option }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "DropDown",
  components: {},
  data() {
    return {
      title: "Choose " + this.typename,
      active: false,
    };
  },
  props: {
    options: Array,
    typename: String,
  },
  methods: {
    toggle() {
      this.active = !this.active;
    },
    itemClicked(event) {
      let code = event.target.value;
      this.$emit("item-clicked", code);
    },
  },
};
</script>