<template>
  <div>
    <h4>Match the specified fields to the file columns</h4>
    <div class="input-group mx-auto m-2 " v-for="(value, key) in columnDict" :key="key">
      <span class="input-group-text" :id="`${key}-span`" style="width: 30%">
        <!-- deletes "_" and changes first letter of each word to uppercase -->
        {{ key.replace("_"," ").replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) }}
      </span>
      <select
        class="dark form-select form-select-md"
        @change="itemClicked"
        :id="`${key}-select`"
      >
        <option v-if="fileColumns.length > 1" value="" disabled selected hidden>
          <!--{{ title }}{{ key.replace("_"," ").replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) }}-->
          Choose Column
        </option>
        <option value="">( Blank )</option>
        <option
          class="dropdown-item"
          :value="col"
          :key="col"
          v-for="col in fileColumns"
          :disabled="Object.values(columnDict).includes(col)"
          :selected="columnDict[key]==col"
        >
          {{ col }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "ColumnMatching",
  components: {},
  data() {
    return {
      title: "Choose Column for ",
    };
  },
  props: {
    fileColumns: Array,
    columnDict: Object,
  },
  updated() {
    // console.log(this.columnDict);
  },
  methods: {
    itemClicked(event) {
      let key = event.target.id.split('-')[0];
      let code = event.target.value;
      this.$emit("item-clicked", key, code);
    },
  },
};
</script>