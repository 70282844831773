<template>
  <div class="row align-items-center justify-content-center">
    <h1 class="page-header col-md-10">Support</h1>
    <p>
      We strive to provide our customers the best service possible.<br /><br />
      We give you here guides on how to use the platform, if you need any further
      assistance, just email us at
      <a href="mailto:support@amphorica.com" target="_blank">support@amphorica.com</a>.<br />
      Our team members would contact you as soon as possible.
    </p>
    <h3>File Upload</h3>
    <div class="col-md-10">
      <div
        style="
          position: relative;
          padding-bottom: calc(48.802083333333336% + 41px);
          height: 0;
        "
      >
        <iframe
          src="https://demo.arcade.software/V9RACVIK2uHZKBrbO88m?embed"
          frameborder="0"
          loading="lazy"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        ></iframe>
      </div>
    </div>
    <h3>Report Walkthrough</h3>
    <div class="col-md-10">
      <iframe
        width="100%"
        height="auto"
        style="aspect-ratio: 16/9"
        src="https://www.youtube.com/embed/JQz199BvVDo"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuportView",
  data() {
    return {};
  },
  mounted() {
    document.title = "Support | Amphorica AirOptimizer™";
    this.$store.commit("setIsLoading", false);
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  border-bottom: 1px solid #ddd;
  padding-bottom: 2px;
  margin-bottom: 1em;
}
h3 {
  margin-top: 1em;
  margin-bottom: 1em;
}
</style>
>
