import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'

import store from '@/store'
import HomeView from '@/views/HomeView.vue'
import LogInView from '@/views/users/LogInView.vue'
import SignUpView from '@/views/users/SignUpView.vue'
import ActivateView from '@/views/users/ActivateView.vue'
import ReportView from '@/views/airoptimizer/ReportView.vue'
import UploadView from '@/views/airoptimizer/UploadView.vue'
import DataView from '@/views/airoptimizer/DataView.vue'
import SupportView from '@/views/platform/SupportView.vue'
import PrivacyView from '@/views/platform/PrivacyView.vue'

const routes = [
  {
    path: '/login',
    name: 'LogIn',
    component: LogInView
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUpView
  },
  {
    path: '/activate',
    name: 'Activate',
    component: ActivateView
  },
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/report',
    name: 'Report',
    component: ReportView,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/upload-data',
    name: 'Upload',
    component: UploadView,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/view-data',
    name: 'ViewData',
    component: DataView,
    meta: {
      requireLogin: true
    },// eslint-disable-next-line
    beforeEnter: (to, from) => {
      // reject the navigation if not a paid user
      return store.state.isPaidUser;
    },
  },
  {
    path: '/support',
    name: 'Support',
    component: SupportView
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin)) {
    if (!store.state.isAuthenticated) {
      next({ name: 'LogIn', query: { to: to.path } });
    } else {
      axios.post('api/v1/token/verify/', {
        "token": store.state.token // eslint-disable-next-line
      }).then(response => {
        next() // eslint-disable-next-line
      }).catch(error => {
        store.commit('removeToken')
        next({ name: 'LogIn', query: { to: to.path } });
      })
    }
  } else {
    next()
  }
})

export default router
