<template>
  <div class="upload">
    <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
      <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
      </symbol>
      <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
      </symbol>
      <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
      </symbol>
    </svg>
    <section class="container">
      <h2 class="mb-5">Upload New Data File</h2>
      <div v-if="errorMsg != ''" class="alert alert-danger d-flex align-items-center w-50 mx-auto justify-content-center" role="alert">
        <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
        <div>
          {{ errorMsg }}
        </div>
      </div>
      <div v-if="successMsg != ''" id="successMsgEl" class="alert alert-success  align-items-center w-50 mx-auto justify-content-center" role="alert">
        <div class="d-flex align-items-center mx-auto justify-content-center">
        <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg>
          {{ successMsg }}
        </div>
        <br>
        <router-link to='/report'><button class="btn btn-outline-success">Go To Report</button></router-link>
        <br>
        <small style="font-size: 0.75em">* new data may take few moments to be rendered in the report</small>
      </div>
      <!-- File Upload Input -->
      <div class="input-group w-50 mx-auto">
        <span
          class="input-group-text"
          id="datafile-span"
          onclick="document.getElementById('datafile').click();"
          >Choose File</span
        >
        <input
          type="file"
          class="form-control form-control-md w-50 mx-auto"
          name="datafile"
          id="datafile"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv"
          @change="fileChosen"/>
      </div>
      <!-- Select Sheet Drop Down -->
      <DropDown
        v-if="validFile"
        @item-clicked="sheetClicked"
        :options="fileSheets"
        :typename="'Sheet'"
        :key="reRenderSheetDropDown"
        class="m-3 mx-auto"
        style="width: 50%"
      />
      <ColumnMatching
        class="content-section"
        v-if="validFile&&chosenSheet!=''&&fileColumns.length"
        :fileColumns="fileColumns"
        :columnDict="columnDict"
        @item-clicked="matchClicked"
      />
      
      <button
        class="btn btn-primary m-3"
        v-if="this.$store.state.isPaidUser"
        @click="uploadClicked"
        :disabled="!validFile || !chosenSheet"
      >
        Upload Data
      </button>

      <div class="mytooltip">
        <button
          class="btn btn-primary m-3"
          v-if="!this.$store.state.isPaidUser"
          disabled
        >
          Upload Data
        </button>

        <span class="mytooltiptext">
          The data upload is only available for premium users. If you would like to use all functionalities, please contact us to upgrade your account.
        </span>

      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import DropDown from "@/components/formcomponents/DropDown.vue";
import ColumnMatching from "@/components/formcomponents/ColumnMatching.vue";

export default {
  name: "UploadView",
  components: {
    DropDown,
    ColumnMatching,
  },
  data() {
    return {
      columnDict: {},
      validFile: false,
      chosenFile: {},
      fileBytes: [], /////////////
      fileColumns: [],
      fileSheets: [],
      chosenSheet: "",
      errorMsg: "",
      successMsg: "",
      reRenderSheetDropDown: false,
    };
  },
  beforeCreate() {
    this.$store.commit("setIsLoading", true);
    document.title = "Upload Data | Amphorica AirOptimizer™";
    axios
      .get("api/v1/users/columnsdict")
      .then((response) => {
        this.columnDict = response.data;
        // console.log(this.columnDict, typeof this.columnDict);
        // console.log(this.columnDict);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted() {
    this.$store.commit("setIsLoading", false);
    // console.log(">isPaidUser", this.$store.state.isPaidUser)
  },
  methods: {
    resetFields() {
      this.validFile = false;
      this.chosenFile = {};
      this.fileBytes = []; /////////////
      this.fileSheets = [];
      this.fileColumns = [];
      this.chosenSheet = "";
      this.errorMsg = "";
      this.successMsg = "";
      this.reRenderSheetDropDown = !this.reRenderSheetDropDown;
    },
    async fileChosen(e) {
      this.$store.commit("setIsLoading", true);
      this.resetFields();
      if (!e.target.files[0]) {
        this.$store.commit("setIsLoading", false);
        return;
      }
      if (
        ![
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
          "text/csv",
        ].includes(e.target.files[0].type)
      ) {
        this.errorMsg = "Please choose a valid excel/csv file";
        this.$store.commit("setIsLoading", false);
        return;
      }
      this.chosenFile = e.target.files[0];
      this.fileBytes = new Uint8Array(await this.chosenFile.arrayBuffer()) /////////////
      const postData = {
        fileName: this.chosenFile.name,
        fileBytes: this.fileBytes,
      };
      axios
        .post("/api/v1/files/get-sheets", postData)
        .then((response) => {
          this.fileSheets = response.data["sheets"];
          this.validFile = true;
          if (this.fileSheets.length == 1) {
            this.chosenSheet = this.fileSheets[0];
            this.sheetClicked(this.chosenSheet);
          }
        })
        .catch((error) => {
          this.errorMsg = error.response.data["msg"];
        })
        .finally(() => {
          this.$store.commit("setIsLoading", false);
        });
    },
    async sheetClicked(sheet) {
      this.$store.commit("setIsLoading", true);
      this.chosenSheet = sheet;
      const postData = {
        fileName: this.chosenFile.name,
        fileBytes: new Uint8Array(await this.chosenFile.arrayBuffer()),
        sheetName: this.chosenSheet,
      };
      axios
        .post("/api/v1/files/get-headers", postData)
        .then((response) => {
          this.fileColumns = response.data["columns"];
        })
        .catch((error) => {
          this.errorMsg = error.response.data["msg"];
        })
        .finally(() => {
          this.$store.commit("setIsLoading", false);
        });
    },
    matchClicked(key, val) {
      this.columnDict[key] = val;
    },
    async uploadClicked() { ///////////
      this.$store.commit("setIsLoading", true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      const postData = {
        fileName: this.chosenFile.name,
        fileBytes: this.fileBytes, ////////////
        sheetName: this.chosenSheet,
      };
      await axios
        .put("/api/v1/users/columnsdict", this.columnDict)
        // .then((response) => {
          // console.log(response);
        // })
        .catch((error) => {
          this.errorMsg = error.response.data["msg"];
        })
        .finally(() => {
          this.$store.commit("setIsLoading", false);
        });
      this.$store.commit("setIsLoading", true);
      axios
        .post("/api/v1/files/upload-file", postData)
        .then(() => {
          this.resetFields();
          this.successMsg = "file uploaded successfully";
          axios.get("api/v1/pbi/refreshdataset");
        })
        .catch((error) => {
          this.errorMsg = error.response.data["msg"];
        })
        .finally(() => {
          this.$store.commit("setIsLoading", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-section {
  background: #202020;
  padding: 10px 20px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  margin-bottom: 20px;
  width: fit-content;
  margin-inline: auto;
}

input[type="file"]::file-selector-button {
  display: none;
}
.input-group-text {
  cursor: pointer;
}

.mytooltip {
  position: relative;
  display: inline-block;
  // border-bottom: 1px dotted black;
  visibility: visible;
}
.mytooltip .mytooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 6px;
  border-color: black;
  border-style: groove;
  border-width: thin;
  padding: 5px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 105%; 
}
.mytooltip:hover .mytooltiptext {
  visibility: visible;
}
</style>