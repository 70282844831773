<template>
    <Transition name="modal"
        @enter="onEnter"
        >
        <div v-if="show" class="modal-mask">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <slot name="header">Edit</slot>
                    </div>
                    <div class="modal-body data-form">
                        <div class="input-group mb-3" style="width: 50%; padding-right: 10px;">
                            <span class="input-group-text">ID</span>
                            <input type="text" class="form-control" placeholder="ID" v-model="newRowData.ID">
                        </div>

                        <div style="display: flex; flex-direction: row;">
                            <div class="input-group mb-3" style="margin-right: 10px;">
                                <span class="input-group-text">Date</span>
                                <input type="date" class="form-control" placeholder="Date" v-model="newRowData.date">
                            </div>
                            <div class="input-group mb-3" style="margin-left: 10px;">
                                <span class="input-group-text">Client</span>
                                <input type="text" class="form-control" placeholder="Client" v-model="newRowData.client">
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: row;">
                            <div class="input-group mb-3" style="margin-right: 10px;">
                                <span class="input-group-text">Origin</span>
                                <input type="text" class="form-control" placeholder="Origin" v-model="newRowData.origin">
                            </div>
                            <div class="input-group mb-3" style="margin-left: 10px;">
                                <span class="input-group-text">Destination</span>
                                <input type="text" class="form-control" placeholder="Destination" v-model="newRowData.destination">
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: row;">
                            <div class="input-group mb-3" style="margin-right: 10px;">
                                <span class="input-group-text">Weight</span>
                                <input type="number" class="form-control" placeholder="Weight" v-model="newRowData.weight">
                            </div>
                            <div class="input-group mb-3" style="margin-left: 10px;">
                                <span class="input-group-text">Type</span>
                                <input type="text" class="form-control" placeholder="Type" v-model="newRowData.type">
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: row;">
                            <div class="input-group mb-3" style="margin-right: 10px;">
                                <span class="input-group-text">Airline-Cost [$]</span>
                                <input type="number" class="form-control" placeholder="Airline-Cost" v-model="newRowData.airline_cost">
                            </div>
                            <div class="input-group mb-3" style="margin-left: 10px;">
                                <span class="input-group-text">Broker-Cost [$]</span>
                                <input type="number" class="form-control" placeholder="Broker-Cost" v-model="newRowData.broker_cost">
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: row;">
                            <div class="input-group mb-3" style="margin-right: 10px;">
                                <span class="input-group-text">Total Cost [$]</span>
                                <input type="number" class="form-control" placeholder="Total Cost" v-model="newRowData.cost">
                            </div>
                            <div class="input-group mb-3" style="margin-left: 10px;">
                                <span class="input-group-text">Sell Rate [$]</span>
                                <input type="number" class="form-control" placeholder="Sell Rate" v-model="newRowData.sell_rate">
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: row;">
                            <div class="input-group mb-3" style="margin-right: 10px;">
                                <span class="input-group-text">Sales Person</span>
                                <input type="text" class="form-control" placeholder="Sales Person" v-model="newRowData.sales_person">
                            </div>
                            <div class="input-group mb-3" style="margin-left: 10px;">
                                <span class="input-group-text">Shipping Airline</span>
                                <input type="text" class="form-control" placeholder="Shipping Airline" v-model="newRowData.shipping_airline">
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <slot name="footer">
                            <button class="btn btn-secondary" @click="$emit('close')" style="float: left">
                                Close
                            </button>
                            <button class="btn btn-success" @click="$emit('save', this.newRowData, this.oldRowData)" style="float: right">
                                Save changes
                            </button>
                        </slot>
                    </div>

                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
export default {
    props: {
        show: Boolean,
        oldRowData: Object,
    },
    data() {
        return {
            newRowData: {
                ID: '',
                MAWB: '',
                date: '', 
                client: '', 
                origin: '', 
                destination: '',
                weight: 0,
                airline_cost: 0,
                broker_cost: 0,
                cost: 0, 
                sell_rate: 0,
                sales_person: '',
                shipping_airline: '',
                type: ''
            }
        }
        
    },

    methods: {
        onEnter() {
            console.log(this.oldRowData)
            this.newRowData = this.oldRowData;
        }
    }
}
</script>

<style>
.input-group-text {
    width: 150px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  overflow-y: auto;
}

.data-form {
  text-align: start;
}

.modal-header {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  /* margin: 20px 0; */
  /* max-height: 80vh; */
  color: black;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>