<template>
  <main class="form-signin col-md-4 offset-md-4">
    <form @submit.prevent="submitForm">
      <!-- <img class="mb-4" src="/docs/5.1/assets/brand/bootstrap-logo.svg" alt="" width="72" height="57"> -->
      <h1 class="h3 mb-3 fw-normal">Log in to Amphorica AirOptimizer</h1>
      <br>
      <div v-if="errors.length">
        <p
          class="alert alert-danger mt-1 mb-3"
          v-for="error in errors"
          v-bind:key="error"
        >
          {{ error }}
        </p>
      </div>

      <div class="form-floating mb-3" style="color:grey;">
        <input
          type="text"
          class="form-control"
          id="floatingInput"
          placeholder="E-Mail Address"
          v-model="email"
        />
        <label for="floatingInput">E-Mail Address</label>
      </div>
      <div class="form-floating mb-5" style="color:grey;">
        <input
          type="password"
          class="form-control"
          id="floatingPassword"
          placeholder="Password"
          v-model="password"
        />
        <label for="floatingPassword">Password</label>
      </div>
      <button class="w-100 btn btn-lg btn-primary" type="submit">
        Log in
      </button>
      <div class="mt-3" style="display: flex; justify-content: flex-end;">
        <a href="signup/" style="text-align: right; color: white;">Sign up for an account</a>
      </div>
    </form>
  </main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      email: "",
      password: "",
      errors: [],
    };
  },
  beforeCreate() {
    this.$store.commit("setIsLoading", true);
    document.title = "Log In | Amphorica AirOptimizer™";
  },
  mounted() {
    this.$store.commit("setIsLoading", false);
  },
  methods: {
    async submitForm() {
      this.$store.commit("setIsLoading", true);
      this.$store.commit("removeToken");
      this.errors = [];
      const formData = {
        email: this.email.toLowerCase(),
        password: this.password,
      };
      await axios
        .post("/api/v1/users/login", formData)
        .then((response) => {
          const token = response.data.jwt;
          this.$store.commit("setToken", token);
          const toPath = this.$route.query.to || "/";
          this.$router.push(toPath);
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${error.response.data[property]}`);
            }
          } else {
            this.errors.push("Something went wrong. Please try again");

            // console.log(JSON.stringify(error));
          }
        });
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>