<template>
  <div>
    <div
      class="bg-dark"
      v-if="$store.state.isAuthenticated && !$store.state.isPaidUser"
    >
      <div id="free-bar">
        This is a demo version,
        <a style="color: rgba(0, 175, 250)" href="https://amphorica.com/#contact" target="_blank"
          >contact us</a
        >
        to upgrade to full version
      </div>
    </div>
    <nav class="navbar navbar-expand-md navbar-dark bg-dark">
      <div class="container-fluid">
        <img id="logo" src="@/assets/AMPHORICA_Logo2-White.png" />
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarToggle"
          aria-controls="navbarToggle"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarToggle">
          <ul
            v-if="$store.state.isAuthenticated"
            class="navbar-nav me-auto mb-2 mb-md-0"
          >
            <li class="nav-item">
              <router-link
                to="/"
                class="nav-link"
                v-bind:class="{ active: $route.name == 'Home' }"
                >Home</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/report"
                class="nav-link"
                v-bind:class="{ active: $route.name == 'Report' }"
                >Report</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/upload-data"
                class="nav-link"
                v-bind:class="{ active: $route.name == 'Upload' }"
                >Upload Data</router-link
              >
            </li>
            <li class="nav-item" v-if="$store.state.isPaidUser">
              <router-link
                to="/view-data"
                class="nav-link"
                v-bind:class="{ active: $route.name == 'ViewData' }"
                >Data</router-link
              >
            </li>
          </ul>
          <ul v-if="$store.state.isAuthenticated" class="navbar-nav">
            <li class="nav-item">
              <router-link
                to="/support"
                class="nav-link"
                v-bind:class="{ active: $route.name == 'Support' }"
                >Support</router-link
              >
            </li>
            <li class="nav-item">
              <button @click="logout()" class="btn btn-danger">Log out</button>
            </li>
            <div style="text-align: center; align: center; display: flex">
              <h4 class="pt-2 ps-2" style="height: 2em">
                Welcome back, {{ $store.state.userfirstname }}!
              </h4>
              <!-- <img
                    src=""
                    class="profile-pic"
                    alt="profile picture"
                  /> -->
            </div>
          </ul>
        </div>
        <!-- <h5 class="pt-2 ps-2">{{ curTime }}</h5> -->
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
  methods: {
    logout() {
      this.$store.commit("removeToken");
      this.$router.push("/login");
    },
  },
};
</script>

<style>
#free-bar {
  width: 100%;
  background-color: rgba(150, 50, 0, 0.5);
  text-align: center;
  padding: 0.5%;
}
</style>
