<template>
  <div class="content-section mt-3" style="height: 80vh; overflow: auto;">
    <h1>AMPHORICA PRIVACY POLICY</h1>
    <p style="text-align: left">
      At Amphorica, we take the privacy of our customers and users very
      seriously. We understand that the personal information you share with us
      is important, and we are committed to protecting it. The following is our
      privacy policy, which outlines our practices and procedures for
      collecting, using, and disclosing personal information.
    </p>
    <br />
    <h5>Information Collection</h5>
    <p style="text-align: left">
      We collect personal information when you register for an account, use our
      services, or otherwise interact with us. This information may include your
      name, email address, phone number, and any other information you choose to
      provide.
    </p>
    <br />
    <h5>Use of Information we Collect</h5>
    <p style="text-align: left">
      We use this information to provide and improve our services, to
      communicate with you, and for other purposes as described in this policy.
      <br />
      Amphorica processes the personal information we collect as described
      above, as necessary for the performance of our Services, to comply with
      our legal and contractual obligations, and to support our legitimate
      interests in maintaining, improving and advertising our Services.
    </p>
    <br />
    <h5>Cookies and Tracking Technologies</h5>
    <p style="text-align: left">
      We and our partners use cookies and other tracking technologies to improve
      your browsing experience and to collect information about how you interact
      with our services. Cookies are small data files that are stored on your
      device when you visit a website. They can be used to remember your
      preferences, to track your browsing history, and for other purposes. You
      can control the use of cookies through your browser settings, but please
      note that disabling cookies may affect your ability to use some features
      of our services.
    </p>
    <br />
    <h5>Sharing and Disclosure of Information</h5>
    <p style="text-align: left">
      We do not share, sell, or rent your personal information to third parties
      without your consent, except as stated in this Privacy Policy. We may
      share your information with third parties for the following purposes:
      <br />
      To provide and improve our services by obtaining third party’s
      complementary services (e.g, hosting services, services from internet
      service providers, operating systems, data security services, billing and
      payment services, data optimization services, etc.) <br />
      To communicate with you <br />
      To comply with legal or regulatory requirements <br />
      To protect the rights, property, or safety of Amphorica, our customers, or
      others
    </p>
    <br />
    <h5>Security</h5>
    <p style="text-align: left">
      These Service Providers may have access to your personal data, depending
      on each of their specific roles and purposes in facilitating, supporting
      and enhancing our Services, and may only use it for such purposes. <br />
      Amphorica may also share your personal data in additional manners,
      pursuant to your explicit approval, or if we are legally obligated to do
      so, or if we have successfully rendered such data provided it is
      non-personal, non-identifiable and anonymous. We may transfer, share or
      otherwise use non-personal and non-identifiable data at our sole
      discretion and without the need for further approval. <br />
      We take reasonable industry standard steps to protect your personal
      information from unauthorized access, use, or disclosure. However, no
      security measures are 100% foolproof, and we cannot guarantee the security
      of your information.
    </p>
    <br />
    <h5>Changes to this Policy</h5>
    <p style="text-align: left">
      We may update this policy from time to time to reflect changes in our
      practices or applicable laws. We will notify you of any changes by posting
      the new policy on our website or through other means. Your continued use
      of our services after any changes indicates your acceptance of the new
      policy.
    </p>
    <br />
    <h5>Exercising User’s Rights and Contact Details</h5>
    <p style="text-align: left">
      As our user, you have rights concerning your personal information
      collected by Amphorica. If you wish to exercise your privacy rights under
      any applicable law, such as the right to request access, modify/update or
      delete any of your personal information, or to restrict or object to
      Amphorica’s processing of your personal information, you may contact us
      with your instructions accordingly. If you have any questions or concerns
      about this policy or our handling of your personal information, please
      contact us at support@amphorica.com.
    </p>
  </div>
</template>

<script>
export default {
  name: "PrivacyView",
  data() {
    return {};
  },
  mounted() {
    document.title = "Privacy Policy | Amphorica AirOptimizer™";
    this.$store.commit("setIsLoading", false);
  },
};
</script>

<style lang="scss" scoped>
.content-section {
  background: #202020;
  padding: 10px 20px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  margin-bottom: 20px;
  width: 66.666%;
  margin-inline: auto;
}
</style>
