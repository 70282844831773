<template>
  <main class="col-md-4 offset-md-4">
    <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
      <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
        <path
          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
        />
      </symbol>
      <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
        <path
          d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
        />
      </symbol>
      <symbol
        id="exclamation-triangle-fill"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path
          d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
        />
      </symbol>
    </svg>
    <div class="mb-3">
      <h3 class="mb-0">Please enter details</h3>
      <small style="color: red">*</small><small> fields are required</small>
    </div>
    <div
      v-if="errorMsg != ''"
      class="alert alert-danger d-flex align-items-center mx-auto justify-content-center"
      role="alert"
    >
      <svg
        class="bi flex-shrink-0 me-2"
        width="24"
        height="24"
        role="img"
        aria-label="Danger:"
      >
        <use xlink:href="#exclamation-triangle-fill" />
      </svg>
      <div style="white-space: pre-wrap;">
        {{ errorMsg }}
      </div>
    </div>
    <div
      v-if="successMsg != ''"
      id="successMsgEl"
      class="alert alert-success align-items-center mx-auto justify-content-center"
      role="alert"
    >
      <div class="d-flex align-items-center mx-auto justify-content-center">
        <svg
          class="bi flex-shrink-0 me-2"
          width="24"
          height="24"
          role="img"
          aria-label="Success:"
        >
          <use xlink:href="#check-circle-fill" />
        </svg>
        {{ successMsg }}
      </div>
      <br>
        <small style="font-size: 0.75em">Redirecting to login page in few moments...</small>
    </div>


    <form @submit.prevent="submitForm" class="form-signup" autocomplete="off">
      <div class="row g-3">
        <div class="col-12">
          <label for="email" class="form-label"
            >E-Mail Address<span style="color: red">*</span></label
          >
          <input
            type="text"
            class="form-control"
            id="email"
            placeholder="address@email.com"
            v-model="email"
            required
          />
          <div class="invalid-feedback">Your email is required.</div>
        </div>
        <div class="col-12">
          <label for="password" class="form-label"
            >Password<span style="color: red">*</span></label
          >
          <input
            type="password"
            class="form-control"
            id="password"
            placeholder="Password"
            v-model="password"
            required
          />
          <div class="invalid-feedback">Your password is required.</div>
        </div>
        <div class="col-12">
          <label for="password" class="form-label"
            >Verify Password<span style="color: red">*</span></label
          >
          <input
            type="password"
            class="form-control"
            id="verifyPassword"
            placeholder="Password"
            v-model="verifyPassword"
            required
          />
          <div class="invalid-feedback">Your password is required.</div>
        </div>
        <div class="col-sm-6">
          <label for="firstName" class="form-label">First Name</label>
          <input
            type="text"
            class="form-control"
            id="firstName"
            placeholder="John"
            v-model="firstName"
          />
        </div>
        <div class="col-sm-6">
          <label for="lastName" class="form-label">Last Name</label>
          <input
            type="text"
            class="form-control"
            id="lastName"
            placeholder="Doe"
            v-model="lastName"
          />
        </div>
        <div class="col-12">
          <label for="companyName" class="form-label">Company</label>
          <input
            type="text"
            class="form-control"
            id="companyName"
            placeholder="Company-Name"
            v-model="companyName"
          />
        </div>
        <div class="col-11" style="margin: 10px auto 0px auto">
          <p>By clicking Agree & Join, you agree to Amphorica´s <a target="_blank" href="https://amphorica.com/terms-of-use/">Terms and Conditions</a>.
          </p>
        </div>
      </div>
      <div class="col-9" style="margin: 0px auto 0px auto">
        <button class="w-100 btn btn-lg btn-primary rounded-pill mt-3" type="submit" >
          Agree & Join
        </button>
      </div>
    </form>
  </main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      email: "",
      password: "",
      verifyPassword: "",
      firstName: "",
      lastName: "",
      companyName: "",
      errorMsg: "",
      successMsg: "",
    };
  },
  beforeCreate() {
    this.$store.commit("setIsLoading", true);
    document.title = "Sign Up | AmphoricaVRP™";
  },
  mounted() {
    this.$store.commit("setIsLoading", false);
  },
  methods: {
    submitForm() {
      this.successMsg = "";
      this.errorMsg = "";
      if ( !this.checkMail(this.email) ) {
        this.errorMsg = "• The entered e-mail address is not valid";
      } else if ( this.checkPasswort(this.password) ){
        if (this.password != this.verifyPassword) {
          this.errorMsg = "• The entered password and verification password do not match";
        } else {
          this.$store.commit("setIsLoading", true);
          const formData = {
            email: this.email,
            password: this.password,
            first_name: this.firstName,
            last_name: this.lastName,
            companyName: this.companyName,
          };
          // console.log(formData);
          axios
            .post("/api/v1/users/register", formData) // eslint-disable-next-line
            .then((response) => {
              const toPath = this.$route.query.to || "/login";
              this.$store.commit("setIsLoading", false);
              this.successMsg = response.data.message;
              setTimeout(() => {
                this.$router.push(toPath);
              }, 10000);
            }) // eslint-disable-next-line
            .catch((error) => {
              // console.log(error.response.data);
              try {
                if (error.response.data["message"]) {
                  this.errorMsg = "• " + error.response.data.message.join("\n• ");
                } else if (error.response.data["email"]) {
                  this.errorMsg = "• " + error.response.data.email;
                }
                else {
                  this.errorMsg = "• Something went wrong, please try again";
                }
              } catch (e) {
                this.errorMsg = "• Something went wrong, please try again";
              }
              this.$store.commit("setIsLoading", false);
            });
        }
      }
    },
    checkMail(mail) {
      var isValid = /^[^@]+@\w+(\.\w+)+\w$/.test(mail);
      return isValid
    },

    checkPasswort(pw) {
      /*
        function checks passwort for a minimum length of 8 character, minimum 1 number and minimum 1 upper case
        if passwort fullfills all conditions, the function returns true
      */
      var hasLength = pw.length >= 8;
      var hasNumber = /\d/.test(pw);
      var hasUppercase = /[A-Z]/.test(pw);
      if (!hasLength) {
        this.errorMsg += "• The password must have at least 8 characters\n";
      }  if (!hasNumber) {
        this.errorMsg += "• The password must contain at least 1 number\n";
      }  if (!hasUppercase) {
        this.errorMsg += "• The password must contain at least 1 upper case letter\n";
      }
      return hasLength && hasNumber && hasUppercase
    }
  },
};
</script>

<style lang="css" scoped>
.form-signup {
  text-align: start;
}
</style>
